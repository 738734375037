import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";

import "./ContactAlertMsg.css";

const ContactAlertMsg = ({ text, alertType }) => {
  return (
    <div className="alert-wrapper">
      <Alert icon={<CheckIcon fontSize="inherit" />} severity={alertType}>
        {text}
      </Alert>
    </div>
  );
};

export default ContactAlertMsg;
