import HeroImg from "../../assets/mainItems/heroImg.png";
import ContactForm from "../../components/ContactComponents/ContactForm";
import "./Contact.css";

import { useContext } from "react";
import WebplaceContext from "../../store/webplace-context";

const Locations = () => {
  const imgCtx =
    useContext(WebplaceContext).homeShowcaseItems.showcase_items[1].img_format;

  return (
    <div className="contact-main-wrapper">
      <img src={HeroImg} alt="main show food" className="image-style" />
      <div className="contact-content-wrapper">
        <ContactForm />
        <img
          src={require(`../../assets/menuItems/menu2.${imgCtx}`)}
          alt="contact addition"
          className="contact-form-img"
        />
      </div>
    </div>
  );
};

export default Locations;
