import { useContext, useEffect, useRef, useState } from "react";
import ButtonFormat from "../shared/ButtonFormat/ButtonFormat";
import "./ContactForm.css";

import emailjs from "@emailjs/browser";
import WebplaceContext from "../../store/webplace-context";
import ContactAlertMsg from "./ContactAlertMsg/ContactAlertMsg";

const ContactForm = () => {
  const formRef = useRef();
  const placeCtx = useContext(WebplaceContext).contactInformation;
  const stylesCtx =
    useContext(WebplaceContext).contactInformation.contact_information_styles;
  const [alert, setAlert] = useState(false);
  const [alertTxt, setAlertTxt] = useState("");
  const [alertStatus, setAlertStatus] = useState("");

  const submitHandler = (event) => {
    event.preventDefault();

    emailjs
      .sendForm(
        placeCtx.email_contact_info.service_id,
        placeCtx.email_contact_info.template,
        formRef.current,
        placeCtx.email_contact_info.public_id
      )
      .then(
        (result) => {
          console.log("Submit status:", result.text);
          setAlertTxt(placeCtx.email_contact_info.success_message);
          setAlertStatus("success");
          setAlert(true);
        },
        (error) => {
          console.log("Submit status:", error.text);
          setAlertTxt(placeCtx.email_contact_info.error_message);
          setAlertStatus("error");
          setAlert(true);
        }
      );
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlert(false);
      return () => clearTimeout(timer);
    }, 4000);
  }, [alert]);

  return (
    <div className="contact-form-wrapper">
      {alert && <ContactAlertMsg text={alertTxt} alertType={alertStatus} />}
      <div className="contact-form-title">{placeCtx.title}</div>
      <form ref={formRef} onSubmit={submitHandler}>
        <div className="form-element-wrapper">
          <label htmlFor="name">{placeCtx.name_subtitle}</label>
          <input
            id="name"
            name="user_name"
            required
            className="input-area"
            type="text"
          />
        </div>
        <div className="form-element-wrapper">
          <label htmlFor="email">{placeCtx.email_subtitle}</label>
          <input
            id="email"
            name="user_email"
            required
            className="input-area"
            type="email"
          />
        </div>
        <div className="form-element-wrapper">
          <label htmlFor="subject">{placeCtx.subject_subtitle}</label>
          <input
            id="subject"
            name="user_subject"
            required
            className="input-area"
            type="text"
          />
        </div>
        <div className="form-element-wrapper">
          <label htmlFor="message">{placeCtx.message_subtitle}</label>
          <textarea
            id="message"
            required
            rows="10"
            className="text-area input-area"
            name="user_msg"
          />
        </div>
        <div className="btn-submit">
          <ButtonFormat
            btnSize={stylesCtx.submit_button.btn_size}
            showArrow={stylesCtx.submit_button.show_arrow}
            arrowAtStart={stylesCtx.submit_button.arrow_at_start}
            arrowColor={stylesCtx.submit_button.arrow_color}
            showBorder={stylesCtx.submit_button.show_border}
            borderColor={stylesCtx.submit_button.border_color}
            backgroundColor={stylesCtx.submit_button.background_color}
            fontColor={stylesCtx.submit_button.font_color}
            capitalizeText={stylesCtx.submit_button.capitalize_text}
            btnText={placeCtx.submit_button_text}
          />
        </div>
      </form>
    </div>
  );
};

export default ContactForm;
